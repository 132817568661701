import React from 'react'
import './Home.css'
import Landing from './Landing'

const Home = () => {
    return(
        <div className="Home">
            <Landing />
        </div>
    )
}

export default Home