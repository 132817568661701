import React from 'react'
import './Home.css'
import Banner from '../../assets/jaleah-banner.png'
import Swaps from '../../assets/easySwaps.png'
import fiveRs from '../../assets/fiveRs.png'
import Clothing from '../../assets/Sustainable-clothing-stores.png'
import Recycling101 from '../../assets/Recycling101.png'
import EarthDay from '../../assets/EarthDay.png'
import ReduceWasteBudget from '../../assets/ReducingWasteBudget.png'
import ReduceWasteMorningRoutine from '../../assets/ReduceWasteMorningRoutine.png'

const Landing = () => {
    return(
        <div className="Landing cream-background">
            <div className="cream-background">
                <img src={Banner} alt='jaleah-banner' className="banner-img" />
                <br />
                <div className="mission-div poppins">
                    <span className="weight600">Hi, I'm Jaleah!</span> <br /> <br />
                    My goal on this page is to document my journey and to inspire and help others along the way. <br /><br />
                    Remember no one is perfect and every little step towards becoming waste free is worth celebrating! <br /><br /> 
                    <span className="weight600">Check out my posts below to learn more!</span>
                </div>
                <hr />
            </div>

            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>Reduce Waste in your Morning Routine </em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>June 4th, 2021</small></em></p>
                <img src={ReduceWasteMorningRoutine} alt='reducingOnABudget' className="clothing-img" />
                <p>Have you ever thought about how much waste you produce each day when getting ready? You might not realize it, but it usually adds up. Here are some tips on how to reduce your waste in your morning routine!&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>In the Shower&nbsp;</strong></p>
                <p>When showering in the morning, even just a few swaps can help reduce your waste. One of these things is your soap. Instead of using plastic bottles filled with body wash, switch to bars of soap! There are many bars of soaps out there that use sustainable packaging such as Ethique. Also, newer in the market are shampoo and conditioner bars. You can swap out your big plastic bottles for bars that do the same thing without the environmental damage! You can switch out your disposable razors for reusable safety razors. Instead of throwing away the whole razor each time, you can switch out the razor heads. Many people find that they actually prefer the shave with a safety razor more than a disposable razor.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Brushing your Teeth&nbsp;</strong></p>
                <p style={{textAlign: 'justify'}}>According to WWF-Australia, plastic toothbrushes take around 500 years to decompose. Plastic toothbrushes fill up our landfills. You can help solve this problem by using a bamboo toothbrush or toothbrushes with refillable heads. Along with finding a more sustainable toothbrush, you can also find less wasteful toothpaste. One of the most popular would be Bite toothpaste bits which&nbsp;are little tablets instead of paste in plastic. They come in glass bottles so everything is plastic free. Bite also has mouthwash bits to replace your standard mouthwash. </p>
                <p style={{textAlign: 'center'}}><strong>Makeup&nbsp;</strong></p>
                <p>One way to decrease your waste in your makeup routine would be by using reusable pads instead of disposable makeup pads or cotton rounds. You can use these over and over again and can throw them in the wash between uses. Another thing you can do is buy makeup from sustainable and ethical companies. Always check the ingredients and try to not buy a ton of plastic products. Ulta now has a Loop x Ulta Beauty collection that allows you to return product’s packaging and they will clean and refill it for you. They also send everything in waste-free packaging. This is a great alternative to buying and throwing away used products. There are many other companies you can check out that are making sustainable changes in their makeup lines.&nbsp;</p>
                <p>Overall, there are a lot of simple changes you can make in your daily routine to become more waste-free. Remember to use up what you already have before buying sustainable products. You can slowly switch these alternatives in and in no time you will have</p>
            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>

            <hr className="landing-hr" />

            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>Reducing Waste on a Budget</em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>May 17th, 2021</small></em></p>
                <img src={ReduceWasteBudget} alt='reducingOnABudget' className="clothing-img" />
                <p>When people think about living sustainably, they often think about how expensive it will be.</p>
                <p>While part of this may be true, there are many ways to reduce your waste on a budget. Here are some ideas!</p>
                <p style={{textAlign: 'center'}}><strong>Reuse</strong></p>
                <p>If you go out and buy all the zero waste products, the cost will add up. Instead, reuse things you</p>
                <p>already have, such as containers and jars and find new purposes for them. This is a great way</p>
                <p>to keep these things out of the landfill and keep from having to purchase something new.</p>
                <p style={{textAlign: 'center'}}><strong>Use up everything you have</strong></p>
                <p>If you throw out things to buy new zero waste products, you are just being more wasteful. The</p>
                <p>products you own have already been produced so you should use them fully until you need to</p>
                <p>replace them. Also, while using up all these things, you can save money for the pricier zero</p>
                <p>waste products you want to buy!</p>
                <p style={{textAlign: 'center'}}><strong>Reusables over disposables</strong></p>
                <p>Reusable products cost more than disposable items. This is because you only need to buy them</p>
                <p>once instead of over and over again. Most reusable products can be a big hit to your budget at</p>
                <p>first, but if you compare the price to buying disposable over and over, the reusables are</p>
                <p>generally less expensive in the long run.</p>
                <p style={{textAlign: 'center'}}><strong>DIY</strong></p>
                <p>Another way to go zero waste on a budget would entail making what you need. Instead of</p>
                <p>buying fancy products, discover things you can make yourself such as cleaning solutions,</p>
                <p>exfoliators, and moisturizers. A lot of these things can be made out of cheap, simple ingredients</p>
                <p>and most are very easy to make!</p>
                <p style={{textAlign: 'center'}}><strong>Second-hand</strong></p>
                <p>One of the best ways to be zero waste is to shop second-hand and this is also perfect when you</p>
                <p>are on a budget! Shopping second-hand can save you a ton of money and you are also</p>
                <p>eliminating the need for something to be produced which conserves resources, making it better</p>
                <p>for the environment. Many people are put off by the idea of shopping second-hand but you can</p>
                <p>find many good pieces at a bargain!</p>
                <p style={{textAlign: 'center'}}><strong>Refuse</strong></p>
                <p>The main R of zero waste is back. The most significant way you can go zero waste on a budget</p>
                <p>is by saying no to something you do not absolutely need. When you cut down on your</p>
                <p>purchases, you are reducing the amount of waste and resources that comes out of that</p>
                <p>purchase, and you are also saving money that can be spent on better products that you need</p>
                <p>now.</p>
                <p>Going zero waste does not need to be expensive. These tips will help you reduce your waste</p>
                <p>while not breaking the bank!</p>
            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>

            <hr className="landing-hr" />
            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>5 Ways to Celebrate Earth Day </em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>April 21st, 2021</small></em></p>
                <img src={EarthDay} alt='earthday' className="clothing-img" />
                <p>Earth Day is celebrated each year on April 22nd. The first Earth Day was in 1970. Every year people pick up litter, educate themselves, and plant trees to celebrate. Here are some things you can do this year to celebrate Earth Day!&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Eat Vegan for the Day&nbsp;</strong></p>
                <p>Eating a plant-based diet is one of the most impactful ways to help the environment. Switching from a meat-based diet to a plant-based diet reduces water and land usage. It also creates less pollution. There are many great vegan recipes out there so you won’t even miss your normal meals! One meal I love to have is the Vegan Potato Wrap. The recipe is from Well and Full can can be found here: <span style={{textDecoration: 'underline'}}>https://wellandfull.com/2017/03/my-favorite-vegan-wrap/</span></p>
                <p style={{textAlign: 'center'}}><strong>Attend an Event&nbsp;</strong></p>
                <p>This is now way easier to do than most years. There are a ton of great Earth Day events and a lot of them are virtual this year. You can search Facebook events or even just google Earth Day events and there are a ton of options you can choose from. You can find an event topic that interests you so it’s even more fun. These events are a great way to educate yourself. One I am going to attend is from my local Solid Waste Agency and it is about recycling.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Watch a Documentary&nbsp;</strong></p>
                <p>The best thing you can do is educate yourself. Educate yourself on the climate crisis and its effects. You can find many amazing documentaries out there on Netflix and other streaming platforms. Some of my favorite documentaries include <em>A Plastic Ocean, Our Planet, The True Cost, and The Clean Bin Project. </em>Again, there are a ton out there to choose from so find something that interests you. Documentaries teach you why we need change and also how you can make that change.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Plant a Tree or Plant&nbsp;</strong></p>
                <p>Planting a tree is one of the most effective things an average person can do. Trees filter our air, provide habitats for animals, and absorb harmful carbon from the atmosphere. If you have the opportunity, try planting a tree or starting a garden. It is impactful and also pretty! Also, bonus points if you use compost for your new garden!&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Spend Time in Nature&nbsp;</strong></p>
                <p>Earth Day is all about celebrating our awesome Earth! Spend time outside taking in all of its glories. You can go on a hike, take a walk, plan a picnic, or even just sit outside. Just try and set aside some time on Earth Day to spend outside and show love to our amazing Earth.&nbsp;</p>
                <p>These were five fairly easy ways to celebrate Earth Day this year! I encourage you to try and do at least one or find other ways to celebrate! Earth Day is a great reminder of the impact we can have on our Earth.</p>
            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>

            <hr className="landing-hr" />
            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>Recycling 101</em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>April 12th, 2021</small></em></p>
                <img src={Recycling101} alt='recycling101' className="clothing-img" />
                <p>Recycling is way more complicated than most people think. It is important to know the right way to recycle because one wrong item in the recycling bin can contaminate the whole bale,
                meaning it will all end up in the landfill. Different recycling programs accept different things in
                different ways so always make sure to check their website for what they can accept. Here are
                some basic rules when recycling pretty much anywhere.</p>
                <p style={{textAlign: 'center'}}><strong>Know what is accepted</strong></p>
                <p>A common term thrown around when talking about recycling is wishcycling. This is when
                someone throws something in the recycling bin hoping it will be accepted. This can cause a lot
                of problems and contaminate actual recyclable materials. Instead of wishcycling, educate
                yourself on what your local recycling program can accept. Most places can accept plastic #1
                and #2, aluminum cans, paper, and cardboard. If you have anything other than those check to
                see if the recycling bin is the right place for it. This makes sure that everything that can be
                recycled, is, and everything that cannot, does not contaminate the other materials.</p>
                <p style={{textAlign: 'center'}}><strong>Make sure it is dry and clean</strong></p>
                <p>Once you know what you can put in your recycling bin, it is important to know how to put them in
                the right way. Your recyclable materials should always be clean. There should never be leftover
                food in them or liquid sloshing around. It does not need to be spotless but it needs to be clean
                so it can be made into new raw materials.</p>
                <p style={{textAlign: 'center'}}><strong>Share with others</strong></p>
                <p>As I said earlier, recycling is complicated. Educate others when you see fit. If you see someone
                recycling something that is not supposed to be, let them know. Most times they are trying to do
                the right thing and do not know that what they are doing is wrong.</p>
                <p style={{textAlign: 'center'}}><strong>Support Recycled Content</strong></p>
                <p>Another thing you can do is buy products that use recycled material. This creates more demand
                from companies to use recycled materials from the recycling agencies. This is an easy way to
                make sure our recycling programs stick around.</p>
                <p>Overall, recycling is a great thing. It allows us to reuse old things and keep them from ending up
                in the landfill. It is up to us to make sure our items are recycled in the right way so this is
                possible.</p>
            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>

            <hr className="landing-hr" />
            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>Sustainable Clothing Stores</em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>March 29th, 2021</small></em></p>
                <img src={Clothing} alt='substainable clothing stores' className="clothing-img" />
                <p>Fast fashion has become one of the biggest issues for our world. Landfills are filling up, there are unsafe working conditions, and poor quality clothing. Sustainable stores try to end this cycle by using better quality materials that are sustainably sourced. This might mean that the prices are a little higher but the pieces will last way longer than clothes from other stores. When you cannot find clothes at the thrift store and need something new these are some sustainable stores with cute clothing and fairly affordable prices to check out.</p>
                <p><strong>Nu-In Fashion</strong></p>
                <p>Nu-In Fashion has a variety of clothing for both men and women. One of the biggest benefits about them is that all their clothes are very affordable meaning you can be sustainable without it costing you a fortune. All of their shipping is CO2 offset, they use recycled materials and safe dyes, and their garments are organic and biodegradable.&nbsp;</p>
                <p>Check it out: <a href="http://www.nu-in.com/" target="_blank" rel="noopener">www.nu-in.com</a></p>
                <p><strong>Nobody’s Child</strong></p>
                <p>Nobody’s Child is a great store for women’s clothing. Their pieces are very fashionable and also affordable. They have clothes for every different style. Nobody’s Child’s sustainability efforts include: 90% of their clothes are made from responsible materials, the store only uses recyclable packaging for shipping, and they are working on developing their Responsible Brand Commitment. This is a newer store so they are still working towards their goal of complete sustainability but it is a great shop to support.</p>
                <p>Check it out: <a href="http://www.nobodyschild.com/" target="_blank" rel="noopener">www.nobodyschild.com</a></p>
                <p><strong>Pact</strong></p>
                <p>Pact is a great store for all your wardrobe basics. They have clothing for men, women, children, and even babies. They sell undergarments, socks, tops, and pants. Most of the stuff is basic so you can match it with many different things to get the most use out of it. Their products are also fairly affordable and comfortable. Pact is partnered with Fair Trade Certified Factories which ensures care for the planet and the people working there. Pact also only uses organic cotton and has the option for to offset the carbon footprint for your shipping. Pact is an awesome place to shop for your closet essentials.</p>
                <p>Check it out: <a href="http://www.wearpact.com/" target="_blank" rel="noopener">www.wearpact.com</a></p>
                <p><strong>Kotn</strong></p>
                <p>Kotn is another great clothing store for men and women’s clothing and also home decorations. They are a little more on the expensive side but the pieces are definitely worth it. Kotn uses direct trade, meaning they get their materials straight from the farmer. They are also a Certified B Corporation. To be a Certified B Corporation, the business has to meet high standards in environmental performance, social performance, and transparency. Kotn is also great because they build and operate schools in rural Egypt where there is no access to education. Kotn is an exceptional company.</p>
                <p>Check it out: <a href="http://www.kotn.com/" target="_blank" rel="noopener">www.kotn.com</a></p>
                <p><strong>Rapuni</strong></p>
                <p>Rapuni is a UK based company with lifestyle clothing for men and women. All their products are made from natural materials and they use renewable energy. Rapuni makes the clothes after they are ordered so there is no surplus that ends up in the landfill. Their return program is another great benefit. When you want to get rid of the clothing you can send it back and they will use the materials for new products.&nbsp;</p>
                <p>Check it out: <a href="http://www.rapanuiclothing.com/" target="_blank" rel="noopener">www.rapanuiclothing.com</a></p>
                <p><strong>Parade</strong></p>
                <p>If you need underwear, look no further. Parade is an affordable company with underwear for men and women. They offer customizable packs and many different styles. You are sure to find something you like. The fabric they use is made of 85% recycled polyamide and free from harmful chemicals. They also use compostable packing. Parade is also a big advocate for body positivity and size inclusion.</p>
                <p>Check it out: <a href="http://www.yourparade.com/" target="_blank" rel="noopener">www.yourparade.com</a></p>
                <p><strong>Allbirds</strong></p>
                <p>I have listed lots of great clothing companies but if you are looking for a great pair of shoes, check out Allbirds. They have different styles such as running shoes, flats, or everyday sneakers and they are always coming out with new styles. Allbirds is a carbon neutral company and also a Certified B Corporation. They also use natural materials and 90% post-consumer recycled packaging. Their shoes are comfortable, fashionable, and good for the environment. What more can you ask for?</p>
                <p>Check it out: <a href="http://www.allbirds.com/" target="_blank" rel="noopener">www.allbirds.com</a></p>
                <p>There are many sustainable stores out there but these are some of my favorite. Next time you need some new clothing check out one of these stores or another sustainable company you found. I am sure you will find something good and will feel good about your purchase knowing that you are helping instead of hurting our environment.</p>

            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>


            <hr className="landing-hr" />
            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>Waste Free Travel</em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>March 8th, 2021</small></em></p>
                <p>With spring break coming up I contemplated how to make my travel more waste free. These are some of the ideas I am implementing on our trip. Maybe you can find something you also want to implement!</p>
                <p  style={{textAlign: 'center'}}><strong>Go Paperless</strong></p>
                <p>The first thing you can do is go paperless when traveling. Instead of printing out all your boarding passes, download your flight app where you can find your mobile boarding pass. This helps cut down on unnecessary paper usage.</p>
                <p  style={{textAlign: 'center'}}><strong>Pack with Reusables</strong></p>
                <p>The next thing is using packing cubes or reusable silicone bags for packing. Whenever I traveled before, I probably used around ten sandwich bags while packing and also usually threw in a couple extra. This adds up in the end so by using packing cubes or silicone bags to separate clothes, you can cut down on the number of disposable plastics you are using.&nbsp;&nbsp;</p>
                <p>Also, something else you can do is pack your reusable straws and reusable cutlery sets with you in your carry-on. A lot of times while traveling we have to grab something quick and on the go, so bringing these helps you refuse the single use silverware and straws that you might use at the restaurant. Kind of along the same line is packing a reusable water bottle. Instead of buying plastic water bottles to stay hydrated, you can continue to fill these. A lot of airports and public places have refill stations so you can fill them up with safe water.&nbsp;</p>
                <p>How many travel size products do you have saved up that are half used? Instead of buying travel size toiletries, invest in reusable travel bottles. You can pour whatever you need into these and reuse them many times. I label mine with a sharpie so I remember what is in each bottle.</p>
                <p  style={{textAlign: 'center'}}><strong>Buy Resale</strong></p>
                <p>The last thing would be entertainment. Instead of buying a new book, check out a resale book store, the library, or find a book digitally. These are all good ways to cut down on your waste and the books you find will be just as good as new.&nbsp;</p>
                <p>Hopefully these ideas helped you find something that you can implement when you travel next! Each step makes a big difference.</p>

            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>
            <hr className="landing-hr" />
            <div className='landing-blog lato'>
                <p  className="l-b-title"  style={{textAlign: 'center'}}><strong><em>First Swaps in my Journey</em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>March 1st, 2021</small></em></p>
                <img src={Swaps} alt='easy-swaps-to-make' className="swaps-img" />
                <p>I wanted to share the first swaps I made in my life. It can be a little overwhelming at the beginning when you realize how much you need to change. These helped me get started and work towards a more waste free life. Remember the most environmentally friendly thing to do is use what you already have.</p>
                <p  style={{textAlign: 'center'}}><strong>Ziploc Bags→ Reusable Silicone Bags</strong></p>
                <p>One of the first things I switched out were Ziploc bags. The average American family goes through roughly 500 Ziploc bags per year. That amounts to around 100 billion plastic bags each year just in America! I started using reusable silicone bags and I love them so much more than the single use plastic bags! It saves you so much money and they’re even dishwasher safe for easy cleaning. They’re also a lot thicker and sturdier which I enjoy.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Plastic Grocery Bags→ Reusable Tote Bags</strong></p>
                <p>Another easy swap is switching out plastic grocery bags for reusable tote bags. The average family brings home around 1,500 grocery bags per year. Reusable tote bags are a great way to cut down that number. I have found these to fit more and they do not rip like the plastic bags so it is better for the environment and for you! I put these in my car so I always have them for going into the grocery store. These have been a really awesome swap for me.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>Disposable Produce Bags→ Reusable Produce Bags</strong></p>
                <p>Another swap in the grocery store is switching out the disposable produce bag from the store and bringing your own reusable produce bag. Think about how many times you have pulled a produce bag from the stand. Bringing your own from home definitely cuts down on your plastic waste. Reusable produce bags are also great because they won’t rip and you can wash your produce right in the bags since it has tiny holes! Again, this swap made my life easier than their plastic counterpart!</p>
                <p style={{textAlign: 'center'}}><strong>Plastic Tupperware→ Glass Containers</strong></p>
                <p>One of the best swaps I made was getting glass containers. We use these for leftovers all the time and other miscellaneous things. Glass containers are so much better than plastic tupperware because it cleans much better and does not get as gross as the plastic ones. They are also much more sturdy because they are glass. For this swap especially, make sure to use up your old plastic tupperware. If you really want to get rid of your plastic containers, take it to a charity where someone else can use them. Once you start using glass containers you will never go back to plastic tupperware!</p>
                <p>I encourage you to try at least one of these swaps in your life! They will definitely make a difference. If you need any help on which to buy, let me know and I can share my favorites:)</p>
                <small>Statistics from Biologicaldiversity.org</small>
            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>
            <hr className="landing-hr" />

            <div className='landing-blog lato'>
                <p className="l-b-title" style={{textAlign: 'center'}}><strong><em>The Five Rs of Zero Waste</em></strong></p>
                <p style={{textAlign: 'center'}}><em><small>February 22nd, 2021</small></em></p>
                <img src={fiveRs} alt='fiveRs' className="fiveRs-img" />
                <p>While going zero waste can be very complicated and mean many different things to different people, most think it can be broken down into the five Rs. You are probably familiar with Reduce, Reuse, Recycle but there are two more we should add into this. The five Rs include Refuse, Reduce, Reuse, Recycle, and Rot in that order. In this post I am going to break down each of these Rs and how to implement them in your life.</p>
                <p style={{textAlign: 'center'}}><strong>1. Refuse</strong></p>
                <p>This is the most important R of them all but also the most difficult I think. The first step to reducing waste is to refuse what you do not need. You can implement this by refusing disposable silverware, straws, or cups at restaurants by bringing your own or refusing to buy something from the store that you do not really need.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>2. Reduce</strong></p>
                <p>This R is all about reducing your consumption. We, as a society, have way more than we need. If we are really aware of our buying patterns, we will realize that most things we buy we do not really need. The only way to reduce your waste is to also reduce your consumption because everything you buy will eventually go to waste.</p>
                <p style={{textAlign: 'center'}}><strong>3. Reuse</strong></p>
                <p>This is definitely my favorite and this R encompasses a lot of different things. First, swapping disposables for reusables. There are a ton of good swaps for almost all of the disposables we use like reusable straws, reusable produce bags, and glass containers instead of plastic or styrofoam take out boxes. Next, buy used. Instead of going out and buying something new that depletes resources, check out thrift stores, Facebook Marketplace, Craigslist, or other secondhand shops. You can usually find what you are looking for at one of these places that are good quality and much cheaper than buying from a store. Lastly, repurpose. Before throwing something out, think about if there is anything else you can use it for. I have repurposed plastic cookie dough jars into storage for hair accessories and even made tote bags out of old fabric placemats. Repurposing is a great way to avoid sending something to a landfill.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>4. Recycle</strong></p>
                <p>Most of us fill our recycling bin with the basic paper and cardboard but there is actually a lot more that you can recycle than you think. Check out your city’s website to see the full list of what you can recycle. Recycling is important because it conserves our natural resources and keeps waste out of the landfill.&nbsp;</p>
                <p style={{textAlign: 'center'}}><strong>5. Rot</strong></p>
                <p>This is definitely one I am still working towards. The last R is about composting. Composting is really important because when food waste is sent to the landfill it takes a very long time to decompose and it also releases methane gas in the process. By separating food scraps and other compostable materials into a compost bin it helps speed up the decomposition. There are many types of composts such as a backyard compost or even city run composts. Check out your city’s website to see what they offer for composting!</p>
                <p>These are the very basic steps to take while working towards a more waste free life. The five Rs are a tool to come back to when you are thinking about purchasing or disposing of something. Even if you implement one of the Rs in your life you are making a big difference. So, how are you going to implement the five Rs into your life?</p>
            </div>
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>
            <hr className="landing-hr" />
            <div className='landing-blog lato'>
                <p className="l-b-title" style={{textAlign: 'center'}}><em>Why do I want to become waste free?</em></p>
                <p style={{textAlign: 'center'}}><em><small>February 15th, 2021</small></em></p>
                <p>This idea was sparked within me when I traveled to Hawaii on a student trip. We went to a beach where we learned about microplastics from a local there and were able to see the damage they caused first hand. We cleaned some up and it made me sad to see this beautiful beach being damaged. After this, I was more conscious of my plastic use but I did not make any big changes.</p>
                <p>Fast forward a year or two, I saw more zero waste posts on social media. I made it my New Year’s Resolution to be more conscious of my waste. I did not really think much of it and was not planning on making a huge lifestyle change. After making this my New Year’s Resolution though, I realized how much waste I produced in my everyday life. I started to research more about why people were going zero waste and I was shocked by the statistics I found. I learned the average American produced 4 pounds of waste <span style={{textDecoration: 'underline'}}>each</span> day and throughout the whole year America produces 56 tons of trash. This means the average American will leave behind around 90,000 pounds of trash for the future generations.&nbsp;</p>
                <p>This is a problem because the trash that is stored in landfills releases methane gas. Methane is a non-CO<span style={{color: '#212121'}}>2 gas that contributes to climate change. Landfills account for 16% of methane emissions in the United States. In addition to the emission of methane, many landfills burn open piles of trash. This burning of piles of trash in the world emits very high levels of carbon dioxide. Carbon dioxide is a greenhouse gas that contributes to global warming. Researchers say that around 40% of the world’s trash is burned like this which presents huge risks for the people living around these sites and for our atmosphere.&nbsp;</span></p>
                <p><span style={{color: '#212121'}}>Besides the trash that goes to these landfills, more than 14 billion pounds of trash is dumped into the ocean each year. This is harmful to all the species in the seas. According to Rubicon.com, 22% of cetaceans, half of all seabird species, a growing list of fish species, and all sea turtle species have been documented with plastic found in or around their bodies.</span></p>
                <p><span style={{color: '#212121'}}>These are large problems. It can be very overwhelming and you may think, “How can I make a difference when it is such a big problem.”&nbsp; My response to this would be every little thing helps create change. Also, you may inspire others to make swaps along the way and before you know it lots of people are making changes.&nbsp;</span></p>
                <p><span style={{color: '#212121'}}>I knew I needed to make a change not only for myself but for the Earth and the future generations. This is why I began my zero waste journey and I hope after learning all this it inspires you to make more of an effort to reduce your waste as well! Remember every little thing makes a big difference!</span></p>
                <p className="l-b-source"><span style={{color: '#212121'}}>Information from Rubicon.com, Earthday.org and EPA.gov</span></p>
            </div>
            <hr className="landing-hr" />
            <div className="landing-email">
                <div className="poppins form-email f-e-div">Do you want me to email you when I post a new article?</div>
                <form className="poppins form-email" name="email" method="POST">
                    <p>
                        <label>Your Email: <input className="form-email-input" type="email" name="email" /></label>
                        <input type="hidden" name="form-name" value="email" />
                    </p>
                    <p>
                        <button  className="form-email-btn light-blue-background" type="submit">Get More Info on Being Waste-Free</button>
                    </p>
                </form>
            </div>
            <hr className="landing-hr" />
            <br /><br /><br />
        </div>
    )
}

export default Landing