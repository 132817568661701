import React from 'react'
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import NavBar from './components/NavBar/NavBar'
import Home from './components/Home/Home'
import Blog from './components/Blog/Blog'
import About from './components/About/About'

function App() {
  return (
    <div className="App">
      {/*<NavBar />*/}
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} />
          {/*<Route exact path="/Blog" component={Blog} />
          <Route exact path="/About" component={About} />*/}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
